import { getQueryStringParams } from '../utils/utils';
import { useLocalStorage } from './useLocalStorage';

export const useLocalPreferredCurrencyCode = () => {
  const [preferredCurrencyCode, setPreferredCurrencyCode] = useLocalStorage('preferredCurrency', 'EUR');

  const urlParams: any = getQueryStringParams(window.location.search);
  if (urlParams.preferredCurrencyCode && urlParams.preferredCurrencyCode !== preferredCurrencyCode) {
    setPreferredCurrencyCode(urlParams.preferredCurrencyCode);
  }

  return [
    preferredCurrencyCode,
    setPreferredCurrencyCode,
  ];
};
