import './booking.scss';

import { CCard, CCol, CRow } from '@coreui/react';
import { getCurrencyInfo } from '@transferz/utils';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Outlet, useLocation } from 'react-router-dom';

import { Header } from '../../components/header/Header';
import { Sidebar } from '../../components/sidebar/Sidebar';
import { phoneCodes } from '../../constants/phoneCodes';
import { useSessionStorage } from '../../hooks/sessionStorage/useSessionStorage';
import { useCreateBooking } from '../../hooks/useCreateBooking';
import { useLocalPreferredCurrencyCode } from '../../hooks/useLocalPreferredCurrencyCode';
import { useRetrieveQuotes } from '../../hooks/useRetrieveQuotes';
import { useQuerySessionData } from '../../queries/sessionData/hooks/useQuerySessionData';
import { getQueryStringParams } from '../../utils/utils';
import { BookingContext } from './BookingContext';

export const Booking: React.FC<any> = () => {
  const { logoUrl } = window.tz_globalConfigs.styling;
  const location = useLocation();
  const { code } = useParams();
  const urlParams: any = getQueryStringParams(location.search);
  const [sessionData, setSessionData] = useState({});
  const [showWidget, setShowWidget] = useState<boolean>(false);
  const [, setFormDataInSession] = useSessionStorage('formData');
  const [preferredCurrencyCode, setPreferredCurrencyCode] = useLocalPreferredCurrencyCode();
  const [quotes, isLoading, error, retrieveQuotes, setError] = useRetrieveQuotes();
  const [booking, isBookingLoading, queryData, createBooking, getQuotesFromQuery] = useCreateBooking();
  const { refetch: refetchSessionData } = useQuerySessionData({
    variables: {
      bookingCode: '',
    },
    skip: true,
  });
  const { partnerStylingOverrides } = window.tz_globalConfigs;

  const getPhoneNumberAndCode = (number: any) => {
    const phoneNumberAndCode = {
      phoneNumber: '',
      code: '',
    };

    phoneCodes.forEach(code => {
      if (number.indexOf(code) === 0) {
        phoneNumberAndCode.code = code;
        phoneNumberAndCode.phoneNumber = number.slice(code.length);
      }
    });

    return phoneNumberAndCode;
  };

  const getSessionData = async () => {
    try {
      const sessionDataResult = await refetchSessionData({
        bookingCode: code,
      });

      const result = sessionDataResult.data?.sessionData;
      const travelAddons = [];
      for (let addon in result.travelAddons) {
        travelAddons.push({ type: addon, amount: result.travelAddons[addon] });
      }
      const data = {
        travelAddons,
        partnerId: window.tz_globalConfigs?.partnerId,
        hubId: result.hub.id,
        hubType: result.hub.hubType,
        hubName: result.hub.name,
        hubNameSecondary: result.hub.autoCompleteLocation,
        vehicleCategory: result.vehicleCategory,
        directionality: result.directionality,
        price: `${getCurrencyInfo(result.currencyCode).symbol} ${result.totalPrice}`,
        destination: {
          address: result.destination.address.formattedAddress,
          countryCode: result.destination.address.countryCode,
          lat: result.destination.position.lat,
          lng: result.destination.position.lng,
        },
        ...(!result.adultPassengerCount ? { passengers: result.passengerCount } : { adultPassengers: result.adultPassengerCount }),
        ...(result.childPassengerCount && { childPassengers: result.childPassengerCount }),
        ...(result.infantPassengerCount && { infantPassengers: result.infantPassengerCount }),
        luggage: result.luggageCount,
        inboundPickup: result.inboundPickupTime,
        outboundPickup: result.outboundPickupTime,
        hubLat: result.hub.position.lat,
        hubLng: result.hub.position.lng,
        isRoundTrip: result.directionality === 'ROUNDTRIP',
        preferredCurrencyCode: result.currencyCode,
      };

      const phoneNumberAndCode = getPhoneNumberAndCode(result.bookerInfo.phone);
      const formData = {
        other: (result.travellerInfo.firstName !==  result.bookerInfo.firstName || result.travellerInfo.lastName !==  result.bookerInfo.lastName),
        bookerDetails: {
          email: result.bookerInfo.email,
          firstName: result.bookerInfo.firstName,
          lastName: result.bookerInfo.lastName,
        },
        travellerDetails: {
          firstName: result.travellerInfo.firstName,
          lastName: result.travellerInfo.lastName,
          email: result.travellerInfo.email,
          driverComments: result.travellerInfo.driverComments,
        },
        number: phoneNumberAndCode.phoneNumber,
        outboundFlightNumber: result.travellerInfo.outboundFlightNumber,
        inboundFlightNumber: result.travellerInfo.inboundFlightNumber,
        terms: false,
        country: {
          value: urlParams.countryCode,
        },
        code: {
          label: phoneNumberAndCode.code,
          value: phoneNumberAndCode.code,
        },
      };
      setSessionData(data);
      setFormDataInSession(formData);
      result.currencyCode && setPreferredCurrencyCode(result.currencyCode);
    } catch (e) {
      console.log('Can not get session data, with error code', e);
    }
  };

  useEffect(() => {
    document.body.style.backgroundColor = partnerStylingOverrides?.['whitelabel.page.backgroundColor'] || window.tz_globalConfigs.styling.backgroundColor || 'white';
    if (urlParams.resultCode && code) {
      getSessionData();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <BookingContext.Provider value={{
      quotes,
      isLoading,
      error,
      retrieveQuotes,
      setError,
      booking,
      isBookingLoading,
      queryData,
      createBooking,
      sessionData,
      preferredCurrencyCode,
      setPreferredCurrencyCode,
      getQuotesFromQuery,
      showWidget,
      setShowWidget,
    }}>
      <CCard style={{ backgroundColor: partnerStylingOverrides?.['whitelabel.page.backgroundColor'] || window.tz_globalConfigs.styling.backgroundColor || 'white' }}>
        <div className="container-lg">
          <CRow>
            <CCol lg="4" className="order-2 order-lg-1">
              <Sidebar sessionData={sessionData}/>
            </CCol>
            <CCol lg="8" className="order-1 order-lg-2">
              <div className="booking-header">
                <a href={`${window.tz_globalConfigs?.customPrefixUrl || '/'}`}>
                  <img className="logo-image" src={partnerStylingOverrides?.['whitelabel.logoUrl'] || logoUrl} alt="" />
                </a>
                <Header />
              </div>
              <Outlet />
            </CCol>
          </CRow>
        </div>
      </CCard>
    </BookingContext.Provider>
  );
};

export default Booking;
