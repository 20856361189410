import {
  CCol,
  CCollapse,
  CForm,
  CFormGroup,
  CInput,
  CInputCheckbox,
  CLabel,
  CLink,
  CRow,
  CTextarea,
} from '@coreui/react';
import { Loading } from '@transferz/fe-components/src/loading';
import { PhoneInput } from '@transferz/fe-components/src/phoneInput';
import { HubType, IFlightInfoRequestParams } from '@transferz/fe-models';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import Select, { components } from 'react-select';

import { ReactComponent as Check } from '../../assets/svgs/check.svg';
import { ReactComponent as ChevronRight } from '../../assets/svgs/chevronRight.svg';
import { ReactComponent as Close } from '../../assets/svgs/close.svg';
import { ReactComponent as Danger } from '../../assets/svgs/danger.svg';
import { countryCodes } from '../../constants/countryCodes';
import { DirectionalityTypes } from '../../constants/DirectionalityTypes';
import { phoneCodes } from '../../constants/phoneCodes';
import { useLocalize } from '../../hooks/useLocalize';
import { useQueryHubDetailsById } from '../../queries/hub/hooks/useQueryHubDetailsById';
import { useQueryIPAddressInfo } from '../../queries/ip/hooks/useQueryIPAddressInfo';
import { useQueryFlightInfo } from '../../queries/transitTracking/hooks/useQueryFlightInfo';

interface FormProps {
  data: any;
  onSubmit?: (data: any) => Promise<any>;
  isRoundTrip?: boolean;
  error?: any;
}

export const ContactInformationForm = ({
  data,
  isRoundTrip,
  onSubmit,
  error,
}: FormProps) => {
  const { translate: t } = useLocalize();
  const flags = useFlags();
  const urlParams: any = new URLSearchParams(window.location.search);
  const hubType = urlParams.get('hubType');
  const directionality = urlParams.get('directionality');
  const [outboundPickup] = urlParams.get('outboundPickup')?.split('T');
  const [inboundPickup] = urlParams.get('inboundPickup')?.split('T');
  const [phoneNumberValue, setPhoneNumber] = useState(data?.bookerDetails?.phone || '');
  const [phoneNumberValidation, setPhoneNumberValidation] = useState('');
  const [phoneCodeValue, setPhoneCode] = useState(data?.code?.value || '');
  const [inboundFlightNumber, setInboundFlightNumber] = useState(data?.inboundFlightNumber || '');
  const [outboundFlightNumber, setOutboundFlightNumber] = useState(data?.outboundFlightNumber || '');
  const [inboundShipName, setInboundShipName] = useState(data?.inboundShipName || '');
  const [outboundShipName, setOutboundShipName] = useState(data?.outboundShipName || '');
  const [inboundTrainNumber, setInboundTrainNumber] = useState(data?.inboundTrainNumber || '');
  const [outboundTrainNumber, setOutboundTrainNumber] = useState(data?.outboundTrainNumber || '');
  const [isOtherChecked, checkOther] = useState(data?.other);
  const [formErrors, setFormErrors] = useState({}) as any;
  const [selectValue, setSelectValue] = useState({}) as any;
  const methods = useForm({ defaultValues: {}, mode: 'onTouched' });

  const { data: IPAddressInfo, loading: IPAddressInfoLoading } = useQueryIPAddressInfo();
  const countryPhonePrefix = IPAddressInfo?.IPAddressInfo?.countryPhonePrefix;
  const [countryCode, setCountryCode] = useState(IPAddressInfo?.IPAddressInfo?.countryCode);
  const { partnerStylingOverrides } = window.tz_globalConfigs;
  const countryCodesValues = countryCodes.map(({ code, displayName }) => ({
    label: displayName,
    value: code,
  }));
  const { data: { hubById: hubDetails } = {} } = useQueryHubDetailsById({
    variables: {
      id: Number(urlParams.get('hubId') ?? '0'),
    },
  });

  const hubIataCode = hubDetails?.details.find(({ code }) => code === 'iata')?.value;

  const { refetch: getFlightInfo } = useQueryFlightInfo({
    variables: {
      params: {
        iataFlightNumber: undefined,
        arrivalDate: undefined,
      },
      skip: true,
    },
  });

  useEffect(() => {
    if (error) {
      handleErrors(error);
    }
    // eslint-disable-next-line
  }, [error]);

  if (IPAddressInfoLoading) {
    return <Loading />;
  }

  const handleErrors = (error: any) => {
    const errors = error?.body?.errors;
    const errorObj: any = {};
    if (Array.isArray(errors)) {
      errors.forEach((error: any) => {
        errorObj[error.field] = error.message;
      });
    }
    setFormErrors(errorObj);
  };

  const renderFormGroupColumn = (
    field: any,
    label: string,
    isHidden: boolean = false,
    setState?: any,
  ) => {
    return (
      <CCol md={6}>
        <CFormGroup>
          <CLabel style={{ color: partnerStylingOverrides?.['whitelabel.page.textColor'] || window.tz_globalConfigs?.styling?.backgroundContrastColor || '#51536d' }} className="register-label col-form-label">{label}</CLabel>
          {renderFormInput(field, isHidden, setState)}
        </CFormGroup>
      </CCol>
    );
  };

  const renderFormInput = (
    field: any,
    isHidden: boolean = false,
    setState: any = (val: any) => { },
  ) => {
    const { register, errors, watch, getValues, control, setValue, trigger } = methods;
    let fieldError: any;
    if ((errors as any)[field?.key]) {
      fieldError = (errors as any)[field.key].ref.name;
    }
    const fieldFirstKey = field.key.split('.')[0];
    const fieldSecondKey = field.key.split('.')[1];
    return (
      <>
        {
          (field.type === 'text' || field.type === 'email') && (
            <>
              <div className={`input-container ${flags?.dataPreselected
                ? ((errors as any)?.[fieldFirstKey]?.[fieldSecondKey] || (errors as any)?.[field.key])
                  ? 'field-error'
                  : (getValues(field.key) || (getValues() as any)?.[fieldFirstKey]?.[fieldSecondKey])
                    ? 'field-confirm'
                    : ''
                : ''}
                `}>
                {!flags?.dataPreselected && (
                  <span onClick={() => {
                    // @ts-ignore
                    setValue(field.key, '');
                    setState('');
                  }}>
                    <Close />
                  </span>
                )}
                <CInput
                  id={field.key}
                  name={field.key}
                  type={isHidden ? 'hidden' : field.type}
                  placeholder={field.placeholder}
                  onChange={(e) => {
                    setState((e.target as HTMLInputElement).value);
                  }}
                  style={{ color: partnerStylingOverrides?.['whitelabel.forms.textColor'] }}
                  // @ts-ignore
                  invalid={!!errors?.[field?.key]}
                  required={(!flags?.dataPreselected && field.validations?.required) ?? false}
                  defaultValue={field.defaultValue}
                  innerRef={register(typeof field.validations === 'function' ? field.validations(getValues, watch) : field.validations) as any}
                  disabled={typeof field.disabled === 'function' ? field.disabled(watch) : field.disabled}
                  min={field?.min}
                  max={field?.max}
                  step={field?.step}
                  data-testid={`input_${field.key.replace('.', '-')}`}
                />
                {flags?.dataPreselected && (getValues(field.key) || (getValues() as any)?.[fieldFirstKey]?.[fieldSecondKey]) && !((errors as any)?.[field.key] || (errors as any)?.[fieldFirstKey]?.[fieldSecondKey]) && (
                  <div className="input-validation-mark"><Check /></div>
                )}
                {flags?.dataPreselected && ((errors as any)?.[field.key] || (errors as any)?.[fieldFirstKey]?.[fieldSecondKey]) && (
                  <div className="input-validation-mark"><Danger /></div>
                )}
              </div>
              {flags?.dataPreselected
                ? (
                  <>
                    {(errors as any)?.[field.key] && <div className="input-error">{(errors as any)?.[field.key]?.message}</div>}
                    {(errors as any)?.[fieldFirstKey]?.[fieldSecondKey] && <div className="input-error">{(errors as any)?.[fieldFirstKey]?.[fieldSecondKey]?.message}</div>}
                  </>
                )
                : (
                  <>
                    {formErrors[field.key] && <div className="input-error">{t('register.lessThanLimit')}</div>}
                    {(fieldError === 'outboundFlightNumber' || fieldError === 'inboundFlightNumber') && <div className="input-error">{t('register.invalidFlightNumber')}</div>}
                    {((errors as any)?.bookerDetails?.phone && field.key === 'bookerDetails.phone') && <div className="input-error">{phoneNumberValidation}</div>}
                  </>
                )
              }
            </>
          )
        }
        {
          (field.type === 'phone' && (
            <PhoneInput
              onChange={(number: string, countryCode: string) => {
                setState(number);
                setCountryCode(countryCode);
              }}
              initialValue={data?.bookerDetails?.phone || countryPhonePrefix}
              label={t('register.phone')}
              innerRef={register(typeof field.validations === 'function' ? field.validations(getValues, watch) : field.validations) as any}
              errorMsg={t('register_invalidNumber')}
              // @ts-ignore
              invalid={!!(errors as any)?.bookerDetails?.phone}
            />
          ))
        }
        {
          field.type === 'textarea'
          && <CTextarea
            id={field.key}
            name={field.key}
            rows={field.rows}
            placeholder={field.placeholder}
            onChange={(e) => setState((e.target as HTMLTextAreaElement).value)}
            style={{ color: partnerStylingOverrides?.['whitelabel.forms.textColor'] }}
            // @ts-ignore
            invalid={!!errors?.[field.key]}
            defaultValue={field.defaultValue}
            innerRef={register(typeof field.validations === 'function' ? field.validations(getValues, watch) : field.validations) as any}
            disabled={typeof field.disabled === 'function' ? field.disabled(watch) : field.disabled}
          />
        }
        {
          field.type === 'checkbox'
          && <>
            {
              field.values.map((item: any, idx: number) => {
                const { label, value } = item;
                let checked = false;
                return (
                  <CFormGroup
                    variant="custom-checkbox"
                    className={`checkbox-primary ${(flags?.dataPreselected && (errors as any)?.[field.key]) ? 'error-checkbox' : ''}`}
                    key={field.key}
                    data-testid={`checkbox_${field.key}`}
                    inline
                  >
                    <CInputCheckbox
                      id={field.key}
                      key={idx}
                      className="mr-1"
                      name={field.key}
                      defaultChecked={checked}
                      defaultValue={value}
                      required={!flags?.dataPreselected && field.validations?.required}
                      innerRef={register(typeof field.validations === 'function' ? field.validations(getValues) : field.validations) as any}
                    />
                    {label && <CLabel style={{ color: window.tz_globalConfigs?.styling?.backgroundContrastColor || '#51536d' }} htmlFor={field.key}>{label}</CLabel>}
                  </CFormGroup>
                );
              })
            }
          </>
        }
        {
          field.type === 'select'
          && <Controller
            name={field.key}
            render={({ onChange: onSelectChange, ref }: any) => {
              return (
                <>
                  <Select
                    onChange={(selected: any) => {
                      setSelectValue({ ...selectValue, [field.key]: selected.value });
                      field.onChange && typeof field.onChange === 'function' && field.onChange(selected, setValue);
                      onSelectChange(selected);
                    }}
                    onBlur={() => {
                      field.onBlur && typeof field.onBlur === 'function' && field.onBlur(trigger);
                    }}
                    options={field.values}
                    inputRef={ref}
                    defaultValue={field.defaultValue}
                    isDisabled={typeof field.disabled === 'function' ? field.disabled(watch) : field.disabled}
                    components={{
                      GroupHeading: (props: any) => (
                        field.selectOptions?.isGrouped
                          ? <components.GroupHeading {...props} />
                          : null
                      ),
                    }}
                    styles={
                      {
                        groupHeading: (base: any) => {
                          if (field.selectOptions?.isGrouped) {
                            return {
                              ...base,
                              color: 'black',
                              fontSize: 14,
                            };
                          }
                        },
                        input: (base: any) => ({
                          ...base,
                          color: partnerStylingOverrides?.['whitelabel.forms.textColor'],

                        }),
                        placeholder: (base: any) => ({
                          ...base,
                          color: partnerStylingOverrides?.['whitelabel.forms.placeholderTextColor'] || '#808080',
                        }),
                        singleValue: (base: any) => ({
                          ...base,
                          color: partnerStylingOverrides?.['whitelabel.forms.textColor'],
                        }),
                      }
                    }
                    isMulti={field?.isMulti}
                    placeholder={field.placeholder}
                  />
                  {
                    field.validations.required
                    && <input
                      className="hidden-input m-0 p-0"
                      tabIndex={-1}
                      autoComplete="off"
                      onChange={() => { }}
                      value={!flags?.dataPreselected ? (selectValue[field.key] || getValues(field.key) || '') : (selectValue[field.key] || getValues(field.key) || (Object.values(data?.[field.key]).length && data?.[field.key]) || '')}
                      required
                    />
                  }
                </>
              );
            }}
            rules={typeof field.validations === 'function' ? field.validations(getValues) : field.validations}
            control={control}
            defaultValue={field.defaultValue}
          />
        }
      </>
    );
  };

  const customContactInformationStyles = {
    '--whitelabel-page-text-color-50': window.tz_globalConfigs?.partnerStylingOverrides?.['whitelabel.page.textColor@50%'],
    '--whitelabel-forms-placeholder-text-color': window.tz_globalConfigs?.partnerStylingOverrides?.['whitelabel.forms.placeholderTextColor'],
  } as React.CSSProperties;

  const validateFlightNumberRealTime = async (params: IFlightInfoRequestParams) => {
    if (!params.iataFlightNumber) {
      return true;
    }

    if (params.iataFlightNumber.length > 2) {
      const { data: { flightInfo } } = await getFlightInfo({
        params,
        skip: false,
      });

      if (flightInfo?.length) {
        return true;
      }
    }

    return t('register.invalidFlightNumber');
  };
  const fields = {
    other: {
      key: 'other',
      type: 'checkbox',
      label: t('register.other'),
      values: [{
        label: '',
        value: data?.other,
      }],
    },
    terms: {
      key: 'terms',
      type: 'checkbox',
      values: [{
        label: (
          <>
            {t('register.termsTextStart')}
            <CLink
              style={{ color: partnerStylingOverrides?.['whitelabel.links.textColor'] }}
              href={`${window.tz_globalConfigs?.customPrefixUrl || ''}/terms-and-conditions`}
              target="_blank"
            >
              {t('register.termsAndConditions')}
            </CLink>
            {t('register.termsTextEnd')}
            <CLink
              style={{ color: partnerStylingOverrides?.['whitelabel.links.textColor'] }}
              href={`${window.tz_globalConfigs?.customPrefixUrl || ''}/privacy-policy`}
              target="_blank"
            >
              {t('register.privacy')}
            </CLink>
          </>
        ),
        value: false,
      }],
      validations: { required: true },
    },
    email: {
      key: 'bookerDetails.email',
      type: 'email',
      defaultValue: data?.bookerDetails?.email || '',
      label: t('register.email'),
      validations: {
        ...(flags?.dataPreselected
          ? {
            required: t('register.required'),
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
              message: t('register_invalidEmail'),
            },
          }
          : { required: true }
        ),
      },
    },
    emailForOther: {
      key: 'travellerDetails.email',
      type: 'email',
      defaultValue: data?.other ? data?.travellerDetails?.email : '',
      label: t('register.emailForOther'),
      validations: {
        ...(flags?.dataPreselected
          ? {
            required: isOtherChecked && t('register.required'),
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
              message: t('register_invalidEmail'),
            },
          }
          : { required: isOtherChecked }
        ),
      },
    },
    firstName: {
      key: 'bookerDetails.firstName',
      type: 'text',
      defaultValue: data?.bookerDetails?.firstName || '',
      label: t('register.firstName'),
      validations: { required: flags?.dataPreselected ? t('register.required') : true },
    },
    lastName: {
      key: 'bookerDetails.lastName',
      type: 'text',
      defaultValue: data?.bookerDetails?.lastName || '',
      label: t('register.lastName'),
      validations: { required: flags?.dataPreselected ? t('register.required') : true },
    },
    firstNameForOther: {
      key: 'travellerDetails.firstName',
      type: 'text',
      defaultValue: data?.other ? data?.travellerDetails?.firstName : '',
      label: t('register.firstName'),
      validations: { required: flags?.dataPreselected ? isOtherChecked && t('register.required') : isOtherChecked },
    },
    lastNameForOther: {
      key: 'travellerDetails.lastName',
      type: 'text',
      defaultValue: data?.other ? data?.travellerDetails?.lastName : '',
      label: t('register.lastName'),
      validations: { required: flags?.dataPreselected ? isOtherChecked && t('register.required') : isOtherChecked },
    },
    code: {
      key: 'code',
      type: 'select',
      label: t('register.phone'),
      values: phoneCodes.map((code: string) => ({
        label: code,
        value: code,
      })),
      onChange: (selected: any) => {
        setPhoneCode(selected.value);
      },
      onBlur: (trigger: any) => {
        trigger('bookerDetails.phone');
      },
      placeholder: '+1',
      validations: { required: true },
    },
    number: {
      key: 'bookerDetails.phone',
      type: 'text',
      defaultValue: phoneNumberValue,
      placeholder: '23456789',
      validations: {
        validate: (value: any) => {
          const fullNumber = `${phoneCodeValue}${value}`;
          if (value) {
            const isValidPhoneNumber = (/^\+[1-9]\d{1,14}$/i).test(fullNumber);
            if (!isValidPhoneNumber) {
              setPhoneNumberValidation(t('register.invalidNumber'));
            }
            return isValidPhoneNumber;
          } else {
            setPhoneNumberValidation(t('register.required'));
            return false;
          }
        },
      },
    },
    phone: {
      key: 'bookerDetails.phone',
      type: 'phone',
      validations: {
        required: true,
        pattern: {
          value: /^[0-9]{6,}$/i,
        },
      },
    },
    outboundFlightNumber: {
      key: 'outboundFlightNumber',
      type: 'text',
      label: t('register.outboundFlightNumber'),
      placeholder: 'BR075',
      defaultValue: outboundFlightNumber,
      validations: {
        required: flags?.showShipName ? ([
          DirectionalityTypes.OUTBOUND,
          DirectionalityTypes.ROUNDTRIP_OUTBOUND,
        ].includes(directionality) && t('register.required')) : flags?.dataPreselected ? t('register.required') : true,
        ...(flags?.realtimeFlightNumberValidation ? {
          validate: (v: string) => validateFlightNumberRealTime({
            iataFlightNumber: v,
            ...([
              DirectionalityTypes.OUTBOUND,
              DirectionalityTypes.ROUNDTRIP_OUTBOUND,
            ].includes(directionality) ? {

                /*
                 * If the first trip is outbound,
                 * then we have to check for arrivals
                 */
                arrivalAirportIataCode: hubIataCode,
                arrivalDate: outboundPickup,
              } : {
                departureAirportIataCode: hubIataCode,
                departureDate: inboundPickup,
              }),
          }),
        } : {
          pattern: {
            value: /^[A-Za-z]{1,3}[-\s]?[0-9]{1,5}$/i,
            message: t('register.invalidFlightNumber'),
          },
        }),
      },
    },
    outboundShipName: {
      key: 'outboundShipName',
      type: 'text',
      label: t('register.outboundShipName'),
      defaultValue: outboundShipName,
      validations: {
        required: [
          DirectionalityTypes.OUTBOUND,
          DirectionalityTypes.ROUNDTRIP_OUTBOUND,
        ].includes(directionality) && t('register.required'),
      },
    },
    outboundTrainNumber: {
      key: 'outboundTrainNumber',
      type: 'text',
      label: t('register.outboundTrainNumber'),
      defaultValue: outboundTrainNumber,
    },
    country: {
      key: 'country',
      type: 'select',
      label: t('register.country'),
      values: countryCodesValues,
      defaultValue: data?.country || (flags?.dataPreselected && countryCodesValues.find((item: any) => item.value === countryCode)),
      validations: { required: true },
    },
    inboundFlightNumber: {
      key: 'inboundFlightNumber',
      type: 'text',
      label: t('register.inboundFlightNumber'),
      placeholder: 'BR075',
      defaultValue: inboundFlightNumber,
      validations: {
        required: flags?.showShipName ? (directionality === DirectionalityTypes.ROUNDTRIP_INBOUND && t('register.required')) : flags?.dataPreselected ? t('register.required') : true,
        ...(flags?.realtimeFlightNumberValidation ? {
          validate: (v: string) => validateFlightNumberRealTime({
            iataFlightNumber: v,
            ...(DirectionalityTypes.ROUNDTRIP_INBOUND === directionality ? {

              /*
               * If the first trip is inbound, then the second trip is outbound,
               * meaning we check for arrivals
               */
              arrivalAirportIataCode: hubIataCode,
              arrivalDate: outboundPickup,
            } : {
              departureAirportIataCode: hubIataCode,
              departureDate: inboundPickup,
            }),
          }),
        } : {
          pattern: {
            value: /^[A-Za-z]{1,3}[-\s]?[0-9]{1,5}$/i,
            message: t('register.invalidFlightNumber'),
          },
        }),
      },
    },
    inboundShipName: {
      key: 'inboundShipName',
      type: 'text',
      label: t('register.inboundShipName'),
      defaultValue: inboundShipName,
      validations: {
        required: directionality === DirectionalityTypes.ROUNDTRIP_INBOUND && t('register.required'),
      },
    },
    inboundTrainNumber: {
      key: 'inboundTrainNumber',
      type: 'text',
      label: t('register.inboundTrainNumber'),
      defaultValue: inboundTrainNumber,
    },
    driverComments: {
      key: 'travellerDetails.driverComments',
      type: 'textarea',
      defaultValue: data?.travellerDetails?.driverComments || '',
      label: t('register.comments'),
    },
  };

  return (
    <FormProvider {...methods}>
      <CForm
        id="registerForm"
        className={`form-horizontal register ${flags?.dataPreselected ? 'dataPreselected-design' : ''}`}
        style={customContactInformationStyles}
        onSubmit={methods.handleSubmit((data: any) => {
          const _data = {
            ...data,
            ...(flags?.dataPreselected && { country: countryCodesValues.find((item: any) => item.value === (countryCode || 'US')) }),
            bookerDetails: {
              ...data?.bookerDetails,
              phone: phoneNumberValue,
            },
          };
          (onSubmit as Function)(flags?.dataPreselected ? _data : data)
            .catch((error: any) => {
              console.log(error);
            });
        })}
      >
        <CRow>
          <CCol xs={12} className="for-other-checkbox mb-3">
            <CFormGroup
              variant="custom-checkbox"
              className="checkbox-primary"
              key={fields.other.key}
              data-testid="checkbox_someone-else"
              inline
            >
              <CInputCheckbox
                id={fields.other.key}
                onChange={(e) => {
                  checkOther(!isOtherChecked);
                }}
                key={fields.other.key}
                className="mr-1"
                name={fields.other.key}
                innerRef={methods.register()}
                defaultChecked={isOtherChecked}
              />
              {<CLabel style={{ color: partnerStylingOverrides?.['whitelabel.page.textColor'] || window.tz_globalConfigs?.styling?.backgroundContrastColor || '#51536d' }} htmlFor={fields.other.key}>{fields.other.label}</CLabel>}
            </CFormGroup>
          </CCol>
        </CRow>
        <CRow>
          {renderFormGroupColumn(fields.email, fields.email.label)}
        </CRow>
        <CRow>
          {renderFormGroupColumn(fields.firstName, fields.firstName.label)}
          {renderFormGroupColumn(fields.lastName, fields.lastName.label)}
        </CRow>
        <CCollapse show={isOtherChecked} className={isOtherChecked ? 'for-other-content' : ''}>
          <CRow>
            <CCol md={12}>
              <div className="for-other-title">
                <h3>{t('register.passengerDetails')}</h3>
              </div>
            </CCol>
          </CRow>
          <CRow>
            {renderFormGroupColumn(fields.firstNameForOther, fields.firstNameForOther.label)}
            {renderFormGroupColumn(fields.lastNameForOther, fields.lastNameForOther.label)}
          </CRow>
          <CRow>
            <CCol md={6} className={`${isOtherChecked ? 'd-block' : 'd-none'}`}>
              <CFormGroup>
                <CLabel style={{ color: partnerStylingOverrides?.['whitelabel.page.textColor'] || window.tz_globalConfigs?.styling?.backgroundContrastColor || '#51536d' }} className="register-label col-form-label">{fields.code.label}</CLabel>
                <CRow>
                  {flags?.dataPreselected ? (
                    <CCol md={12}>
                      {renderFormInput(fields.phone, !isOtherChecked, setPhoneNumber)}
                    </CCol>
                  ) : (
                    <>
                      <CCol className="col-md-4 register-select">
                        {renderFormInput(fields.code, false, setPhoneCode)}
                      </CCol>
                      <CCol className="col-md-8">
                        {renderFormInput(fields.number, !isOtherChecked, setPhoneNumber)}
                      </CCol>
                    </>
                  )}
                </CRow>
              </CFormGroup>
            </CCol>
            <CCol md={6} className="contact-text">{t('phonenumber_notification')}</CCol>
          </CRow>
          <CRow>
            {renderFormGroupColumn(fields.emailForOther, fields.emailForOther.label, false)}
            {
              flags?.showShipName ? (
                hubType === HubType.Airport ? (
                  <>
                    <CCol md={isRoundTrip ? 3 : 6}>
                      <CFormGroup>
                        <CLabel
                          style={{
                            color:
                              partnerStylingOverrides?.['whitelabel.page.textColor']
                              || window.tz_globalConfigs?.styling?.backgroundContrastColor
                              || '#51536d',
                          }}
                          className="register-label col-form-label"
                        >
                          {fields.outboundFlightNumber.label}
                        </CLabel>
                        {renderFormInput(
                          fields.outboundFlightNumber,
                          !isOtherChecked,
                          setOutboundFlightNumber,
                        )}
                      </CFormGroup>
                    </CCol>
                    {isRoundTrip && (
                      <CCol md={3}>
                        <CFormGroup>
                          <CLabel
                            style={{
                              color:
                                partnerStylingOverrides?.['whitelabel.page.textColor']
                                || window.tz_globalConfigs?.styling?.backgroundContrastColor
                                || '#51536d',
                            }}
                            className="register-label col-form-label"
                          >
                            {fields.inboundFlightNumber.label}
                          </CLabel>
                          {renderFormInput(
                            fields.inboundFlightNumber,
                            !isOtherChecked,
                            setInboundFlightNumber,
                          )}
                        </CFormGroup>
                      </CCol>
                    )}
                  </>
                ) : hubType === HubType.Seaport ? (
                  <>
                    <CCol md={isRoundTrip ? 3 : 6}>
                      <CFormGroup>
                        <CLabel
                          style={{
                            color:
                              partnerStylingOverrides?.['whitelabel.page.textColor']
                              || window.tz_globalConfigs?.styling?.backgroundContrastColor
                              || '#51536d',
                          }}
                          className="register-label col-form-label"
                        >
                          {fields.outboundShipName.label}
                        </CLabel>
                        {renderFormInput(
                          fields.outboundShipName,
                          !isOtherChecked,
                          setOutboundShipName,
                        )}
                      </CFormGroup>
                    </CCol>
                    {isRoundTrip && (
                      <CCol md={3}>
                        <CFormGroup>
                          <CLabel
                            style={{
                              color:
                                partnerStylingOverrides?.['whitelabel.page.textColor']
                                || window.tz_globalConfigs?.styling?.backgroundContrastColor
                                || '#51536d',
                            }}
                            className="register-label col-form-label"
                          >
                            {fields.inboundShipName.label}
                          </CLabel>
                          {renderFormInput(
                            fields.inboundShipName,
                            !isOtherChecked,
                            setInboundShipName,
                          )}
                        </CFormGroup>
                      </CCol>
                    )}
                  </>
                ) : hubType === HubType.TrainStation ? (
                  <>
                    <CCol md={isRoundTrip ? 3 : 6}>
                      <CFormGroup>
                        <CLabel
                          style={{
                            color:
                              partnerStylingOverrides?.['whitelabel.page.textColor']
                              || window.tz_globalConfigs?.styling?.backgroundContrastColor
                              || '#51536d',
                          }}
                          className="register-label col-form-label"
                        >
                          {fields.outboundTrainNumber.label}
                        </CLabel>
                        {renderFormInput(
                          fields.outboundTrainNumber,
                          !isOtherChecked,
                          setOutboundTrainNumber,
                        )}
                      </CFormGroup>
                    </CCol>
                    {isRoundTrip && (
                      <CCol md={3}>
                        <CFormGroup>
                          <CLabel
                            style={{
                              color:
                                partnerStylingOverrides?.['whitelabel.page.textColor']
                                || window.tz_globalConfigs?.styling?.backgroundContrastColor
                                || '#51536d',
                            }}
                            className="register-label col-form-label"
                          >
                            {fields.inboundTrainNumber.label}
                          </CLabel>
                          {renderFormInput(
                            fields.inboundTrainNumber,
                            !isOtherChecked,
                            setInboundTrainNumber,
                          )}
                        </CFormGroup>
                      </CCol>
                    )}
                  </>
                ) : null
              ) : (
                <>
                  <CCol md={isRoundTrip ? 3 : 6}>
                    <CFormGroup>
                      <CLabel
                        style={{
                          color:
                            partnerStylingOverrides?.['whitelabel.page.textColor']
                            || window.tz_globalConfigs?.styling?.backgroundContrastColor
                            || '#51536d',
                        }}
                        className="register-label col-form-label"
                      >
                        {fields.outboundFlightNumber.label}
                      </CLabel>
                      {renderFormInput(
                        fields.outboundFlightNumber,
                        !isOtherChecked,
                        setOutboundFlightNumber,
                      )}
                    </CFormGroup>
                  </CCol>
                  {isRoundTrip && (
                    <CCol md={3}>
                      <CFormGroup>
                        <CLabel
                          style={{
                            color:
                              partnerStylingOverrides?.['whitelabel.page.textColor']
                              || window.tz_globalConfigs?.styling?.backgroundContrastColor
                              || '#51536d',
                          }}
                          className="register-label col-form-label"
                        >
                          {fields.inboundFlightNumber.label}
                        </CLabel>
                        {renderFormInput(
                          fields.inboundFlightNumber,
                          !isOtherChecked,
                          setInboundFlightNumber,
                        )}
                      </CFormGroup>
                    </CCol>
                  )}
                </>
              )
            }
          </CRow>
        </CCollapse>
        {!isOtherChecked && (
          <CRow className={!isOtherChecked ? 'd-flex' : 'd-none'}>
            <CCol md={6}>
              <CFormGroup>
                <CLabel
                  style={{ color: partnerStylingOverrides?.['whitelabel.page.textColor'] || window.tz_globalConfigs?.styling?.backgroundContrastColor || '#51536d' }}
                  className="register-label col-form-label">
                  {fields.code.label}
                </CLabel>
                <CRow>
                  {flags?.dataPreselected ? (
                    <CCol md={12}>
                      {renderFormInput(fields.phone, isOtherChecked, setPhoneNumber)}
                    </CCol>
                  ) : (
                    <>
                      <CCol className="col-md-4 register-select">
                        {renderFormInput(fields.code, false, setPhoneCode)}
                      </CCol>
                      <CCol className="col-md-8">
                        {renderFormInput(fields.number, isOtherChecked, setPhoneNumber)}
                      </CCol>
                    </>
                  )}
                </CRow>
              </CFormGroup>
            </CCol>
            {flags?.showShipName ? (
              hubType === HubType.Airport ? (
                <>
                  <CCol md={isRoundTrip ? 3 : 6}>
                    <CFormGroup>
                      <CLabel
                        style={{
                          color:
                            partnerStylingOverrides?.[
                              'whitelabel.page.textColor'
                            ]
                            || window.tz_globalConfigs?.styling
                              ?.backgroundContrastColor
                            || '#51536d',
                        }}
                        className="register-label col-form-label"
                      >
                        {fields.outboundFlightNumber.label}
                      </CLabel>
                      {renderFormInput(
                        fields.outboundFlightNumber,
                        isOtherChecked,
                        setOutboundFlightNumber,
                      )}
                    </CFormGroup>
                  </CCol>
                  {isRoundTrip && (
                    <CCol md={3}>
                      <CFormGroup>
                        <CLabel
                          style={{
                            color:
                              partnerStylingOverrides?.[
                                'whitelabel.page.textColor'
                              ]
                              || window.tz_globalConfigs?.styling
                                ?.backgroundContrastColor
                              || '#51536d',
                          }}
                          className="register-label col-form-label"
                        >
                          {fields.inboundFlightNumber.label}
                        </CLabel>
                        {renderFormInput(
                          fields.inboundFlightNumber,
                          isOtherChecked,
                          setInboundFlightNumber,
                        )}
                      </CFormGroup>
                    </CCol>
                  )}
                </>
              ) : hubType === HubType.Seaport ? (
                <>
                  <CCol md={isRoundTrip ? 3 : 6}>
                    <CFormGroup>
                      <CLabel
                        style={{
                          color:
                            partnerStylingOverrides?.[
                              'whitelabel.page.textColor'
                            ]
                            || window.tz_globalConfigs?.styling
                              ?.backgroundContrastColor
                            || '#51536d',
                        }}
                        className="register-label col-form-label"
                      >
                        {fields.outboundShipName.label}
                      </CLabel>
                      {renderFormInput(
                        fields.outboundShipName,
                        isOtherChecked,
                        setOutboundShipName,
                      )}
                    </CFormGroup>
                  </CCol>
                  {isRoundTrip && (
                    <CCol md={3}>
                      <CFormGroup>
                        <CLabel
                          style={{
                            color:
                              partnerStylingOverrides?.[
                                'whitelabel.page.textColor'
                              ]
                              || window.tz_globalConfigs?.styling
                                ?.backgroundContrastColor
                              || '#51536d',
                          }}
                          className="register-label col-form-label"
                        >
                          {fields.inboundShipName.label}
                        </CLabel>
                        {renderFormInput(
                          fields.inboundShipName,
                          isOtherChecked,
                          setInboundShipName,
                        )}
                      </CFormGroup>
                    </CCol>
                  )}
                </>
              ) : hubType === HubType.TrainStation ? (
                <>
                  <CCol md={isRoundTrip ? 3 : 6}>
                    <CFormGroup>
                      <CLabel
                        style={{
                          color:
                            partnerStylingOverrides?.[
                              'whitelabel.page.textColor'
                            ]
                            || window.tz_globalConfigs?.styling
                              ?.backgroundContrastColor
                            || '#51536d',
                        }}
                        className="register-label col-form-label"
                      >
                        {fields.outboundTrainNumber.label}
                      </CLabel>
                      {renderFormInput(
                        fields.outboundTrainNumber,
                        isOtherChecked,
                        setOutboundTrainNumber,
                      )}
                    </CFormGroup>
                  </CCol>
                  {isRoundTrip && (
                    <CCol md={3}>
                      <CFormGroup>
                        <CLabel
                          style={{
                            color:
                              partnerStylingOverrides?.[
                                'whitelabel.page.textColor'
                              ]
                              || window.tz_globalConfigs?.styling
                                ?.backgroundContrastColor
                              || '#51536d',
                          }}
                          className="register-label col-form-label"
                        >
                          {fields.inboundTrainNumber.label}
                        </CLabel>
                        {renderFormInput(
                          fields.inboundTrainNumber,
                          isOtherChecked,
                          setInboundTrainNumber,
                        )}
                      </CFormGroup>
                    </CCol>
                  )}
                </>
              ) : null
            ) : (
              <>
                <CCol md={isRoundTrip ? 3 : 6}>
                  <CFormGroup>
                    <CLabel
                      style={{
                        color:
                          partnerStylingOverrides?.[
                            'whitelabel.page.textColor'
                          ]
                          || window.tz_globalConfigs?.styling
                            ?.backgroundContrastColor
                          || '#51536d',
                      }}
                      className="register-label col-form-label"
                    >
                      {fields.outboundFlightNumber.label}
                    </CLabel>
                    {renderFormInput(
                      fields.outboundFlightNumber,
                      isOtherChecked,
                      setOutboundFlightNumber,
                    )}
                  </CFormGroup>
                </CCol>
                {isRoundTrip && (
                  <CCol md={3}>
                    <CFormGroup>
                      <CLabel
                        style={{
                          color:
                            partnerStylingOverrides?.[
                              'whitelabel.page.textColor'
                            ]
                            || window.tz_globalConfigs?.styling
                              ?.backgroundContrastColor
                            || '#51536d',
                        }}
                        className="register-label col-form-label"
                      >
                        {fields.inboundFlightNumber.label}
                      </CLabel>
                      {renderFormInput(
                        fields.inboundFlightNumber,
                        isOtherChecked,
                        setInboundFlightNumber,
                      )}
                    </CFormGroup>
                  </CCol>
                )}
              </>
            )}
          </CRow>
        )}
        {!flags?.dataPreselected && (
          <CRow>
            <CCol md={6}>
              <CFormGroup className="register-select">
                <CLabel style={{ color: partnerStylingOverrides?.['whitelabel.page.textColor'] || window.tz_globalConfigs?.styling?.backgroundContrastColor || '#51536d' }} className="register-label col-form-label">{fields.country.label} </CLabel>
                {renderFormInput(fields.country)}
              </CFormGroup>
            </CCol>
          </CRow>
        )}
        <CRow className="align-items-center comments">
          <CCol md={12}>
            <CFormGroup>
              <CLabel
                style={{ color: partnerStylingOverrides?.['whitelabel.page.textColor'] || window.tz_globalConfigs?.styling?.backgroundContrastColor || '#51536d' }}
                className="register-label col-form-label"
              >
                {fields.driverComments.label}
              </CLabel>
              <span className="text-muted optional">{t('optional')}</span>
              {renderFormInput(fields.driverComments)}
            </CFormGroup>
          </CCol>
        </CRow>
        <CRow className="align-items-center mb-3 terms-checkbox-row d-flex">
          <CCol md={8}>
            {renderFormInput(fields.terms)}
          </CCol>
          {flags?.dataPreselected && (
            <CCol md={4} className="confirm-button-container">
              <button
                className="button-confirm form-button"
                form="registerForm"
                type="submit"
                data-testid="submit_contact-info"
                style={{ backgroundColor: window.tz_globalConfigs?.styling?.secondaryColor || '#48a947', color: window.tz_globalConfigs?.styling?.secondaryContrastColor || '#fff' }}>
                <span>{t('register.bookNow')}</span>
                <ChevronRight/>
              </button>
            </CCol>
          )}
        </CRow>
      </CForm>
    </FormProvider>
  );
};
