import { ILanguageOption } from '../components/localization/LanguageContext';
import { languagesMap } from '../constants/languagesMap';

export const getQueryStringParams = (query: string) => {
  return query
    ? ((/^[?#]/).test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params: any, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {})
    : {};
};

export const setHtmlTextDirection = (lng: string) => {
  if (lng === 'ar-SA') {
    document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    document.body.classList.add('rtl');
  } else {
    document.getElementsByTagName('html')[0].removeAttribute('dir');
    document.body.classList.remove('rtl');
  }
};

export const getDefaultLanguage = (supportedLanguages: ILanguageOption[]): string => {
  const browserLanguage: string = languagesMap[navigator?.language];
  let lng: string = browserLanguage;

  try {
    const preferredLanguage = localStorage?.getItem('preferredLanguage');
    if (preferredLanguage) {
      lng = JSON.parse(preferredLanguage!);
    }
  } catch (e) {
    console.error(e);
  }

  const isLanguageSupported = supportedLanguages.some((supportedLanguage) => supportedLanguage.language_iso_code === lng);

  return isLanguageSupported ? lng : supportedLanguages[0].language_iso_code;
};
