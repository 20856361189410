import { gql } from '@apollo/client';

export const SESSION_DATA = gql`
  query SessionData($bookingCode: String!) {
    sessionData(bookingCode: $bookingCode) {
      hub {
        id
        name
        position {
          lat
          lng
        }
        address {
          formattedAddress
          streetName
          streetNumber
          city
          postalCode
          region
          countryCode
          establishment
        }
        managedByUserId
        active
        hubType
        minBookWindowHours
        details {
          code
          value
          name
        }
        geographicArea {
          lat
          lng
        }
        autoCompleteName
        autoCompleteLocation
      }
      vehicleCategory
      directionality
      totalPrice
      currencyCode
      travelAddons
      destination {
        id
        position {
          lat
          lng
        }
        address {
          formattedAddress
          streetName
          streetNumber
          city
          postalCode
          region
          countryCode
          establishment
        }
        hub
      }
      passengerCount
      adultPassengerCount
      infantPassengerCount
      childPassengerCount
      luggageCount
      inboundPickupTime
      outboundPickupTime
      bookerInfo {
        firstName
        lastName
        email
        phone
        companyName
        reference
        traveller
        receiveJourneyUpdates
        languageIsoCode
      }
      travellerInfo {
        passengerCount
        adultPassengerCount
        infantPassengerCount
        childPassengerCount
        luggageCount
        firstName
        lastName
        email
        phone
        meetAndGreetSignText
        driverComments
        flightNumber
        trainNumber
        languageIsoCode
      }
    }
  }
`;
